<template>
  <circle
    cx="10"
    cy="10"
    r="8"
    fill="none"
    stroke="currentColor"
    stroke-opacity="0.2"
    stroke-width="3"
  />
  <path
    d="M10 2C11.5823 2 13.129 2.46919 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40034 17.9965 6.93853 17.391C5.47672 16.7855 4.22729 15.7602 3.34824 14.4446C2.46919 13.129 2 11.5823 2 10"
    fill="none"
    stroke="currentColor"
    stroke-width="3"
    stroke-linecap="round"
  />
</template>
